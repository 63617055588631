import type { FC } from 'react';
import React, { useRef, useCallback, useEffect, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { useQuery } from '@apollo/react-hooks';
import { type LoomVideo } from '@loomhq/record-sdk';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/loading-button';
import Tooltip from '@atlaskit/tooltip';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import type { OpenLoomRecorderParams } from '@confluence/loom-utils';
import { useLoomEntryPointVariant, useLoomRecorderEntryPoint } from '@confluence/loom-utils';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { PageHeaderLoomButtonSpotlight } from '@confluence/loom-onboarding';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { getUrlForContentType } from '@confluence/content-types-utils';
import {
	ExperienceTrackerContext,
	LOOM_PAGE_HEADER_CONTENT_INSERTION,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { PageHeaderLoomButtonQuery } from './PageHeaderLoomButtonQuery.graphql';
import type {
	PageHeaderLoomButtonQuery as PageHeaderLoomButtonQueryType,
	PageHeaderLoomButtonQueryVariables,
} from './__types__/PageHeaderLoomButtonQuery';
import { LoomIcon } from './LoomIcon';
import { useLoomInsertUrlState } from './useLoomInsertUrlState';

const i18n = defineMessages({
	loomButtonTooltip: {
		id: 'page-header-loom-button.tooltip',
		description: 'Tooltip for the button in the page header to record a Loom video.',
		defaultMessage: 'Record a Loom video',
	},
});

type PageHeaderLoomButtonComponentProps = {
	contentId: string;
	spaceKey: string;
	contentType: string;
	flags: FlagsStateContainer;
};

export enum PostLoomRecordingCTA {
	EDITOR_INSERT = 'editorInsert',
	PAGE_COMMENT_INSERT = 'pageCommentInsert',
	COPY_LINK = 'copyLink',
	OPEN_ON_LOOM = 'openOnLoom',
}

const PageHeaderLoomButtonComponent: FC<PageHeaderLoomButtonComponentProps> = ({
	contentId,
	spaceKey,
	contentType,
	flags,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const isItemMarked = useRef(false);
	const markItemRef = useRef<((item: HEADER_ITEM_KEYS) => void) | undefined>();
	const isLoomPageHeaderInsertIntoContentEnabled = FeatureGates.checkGate(
		'loom_page_header_insert_into_content',
	);
	const isLoomPageHeaderCopyLinkOnlyCta = FeatureGates.checkGate(
		'loom_page_header_copy_link_only_cta',
	);
	const { loadEditor } = useEditPageLoadingActions();
	const [, loomInsertUrlActions] = useLoomInsertUrlState();

	const saveLoomUrlAndLoadEditor = (video: LoomVideo) => {
		loomInsertUrlActions.updateFullPageEditorLoomUrl(video);
		const redirectUrl = getUrlForContentType({
			contentType,
			spaceKey,
			contentId,
			redirectToEditor: true,
		});
		loadEditor({
			contentId,
			spaceKey,
			redirectUrl,
			contentType,
		});
	};

	const beginExperienceTracker = (postLoomRecordingCTA: PostLoomRecordingCTA) => {
		experienceTracker.start({
			name: LOOM_PAGE_HEADER_CONTENT_INSERTION,
			timeout: ExperienceTimeout.LOOM_PAGE_HEADER_CONTENT_INSERTION,
			attributes: { postLoomRecordingCTA },
		});
	};

	const entryPointVariant = useLoomEntryPointVariant();
	const isEntryPointEnabled = ['BETA', 'CO_USE', 'CROSS_FLOW', 'CROSS_JOIN'].includes(
		entryPointVariant,
	);

	const { data } = useQuery<PageHeaderLoomButtonQueryType, PageHeaderLoomButtonQueryVariables>(
		PageHeaderLoomButtonQuery,
		{
			skip: !isEntryPointEnabled,
			variables: { contentId },
		},
	);

	const isContentContributor = Boolean(data?.contentContributors?.isCurrentUserContributor);

	let openLoomRecorderParams: OpenLoomRecorderParams | undefined;
	let postLoomRecordingCTA: PostLoomRecordingCTA = PostLoomRecordingCTA.OPEN_ON_LOOM;
	let resetInsertFunctionOnUnmount = false;

	if (isLoomPageHeaderInsertIntoContentEnabled) {
		const contentData = data?.content?.nodes?.[0];

		const canEdit = contentData?.operations?.some(
			(operation) => operation?.operation === 'update' && operation?.targetType === contentType,
		);
		const canComment = contentData?.operations?.some(
			(operation) => operation?.targetType === 'comment' && operation?.operation === 'create',
		);

		if (canEdit && isContentContributor) {
			// editor mode insert
			postLoomRecordingCTA = PostLoomRecordingCTA.EDITOR_INSERT;
			openLoomRecorderParams = {
				onInsert: (_, video: LoomVideo) => {
					beginExperienceTracker(postLoomRecordingCTA);
					saveLoomUrlAndLoadEditor(video);
				},
				// intentionally not localized because Loom RecordSDK does not support i18n
				insertButtonText: 'Insert Loom video',
			};
		} else if (canComment) {
			// page comment insert
			postLoomRecordingCTA = PostLoomRecordingCTA.PAGE_COMMENT_INSERT;
			// Set to true so that CTA 'Add to comment' will not render if user has navigated to
			// different page post Loom recording.
			resetInsertFunctionOnUnmount = true;
			openLoomRecorderParams = {
				onInsert: (_, video: LoomVideo) => {
					beginExperienceTracker(postLoomRecordingCTA);
					loomInsertUrlActions.updatePageCommentEditorLoomUrl(video);
					const openPageCommentEditorButton = document.getElementById('addCommentButton');
					openPageCommentEditorButton && openPageCommentEditorButton.click();
				},
				// intentionally not localized because Loom RecordSDK does not support i18n
				insertButtonText: 'Add to comment',
			};
		} else {
			postLoomRecordingCTA = PostLoomRecordingCTA.COPY_LINK;
			// Undefined openLoomRecorderParams results in
			// Loom SDK rendering a single CTA of 'Copy link'
			openLoomRecorderParams = isLoomPageHeaderCopyLinkOnlyCta
				? undefined
				: {
						onInsert: (videoUrl: string) => {
							window.open(videoUrl, '_blank');
						},
						// intentionally not localized because Loom RecordSDK does not support i18n
						insertButtonText: 'Open on Loom',
					};
		}
	} else {
		openLoomRecorderParams = {
			onInsert: (videoUrl: string) => window.open(videoUrl, '_blank'),
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Open on Loom',
		};
	}

	const buttonRendered = useCallback(() => {
		if (!isItemMarked.current && markItemRef.current) {
			isItemMarked.current = true;
			markItemRef.current(HEADER_ITEM_KEYS.RECORD_LOOM);
		}
	}, []);

	const { formatMessage } = useIntl();

	const isPageHeaderLoomButtonEnabled = useBooleanFeatureFlag(
		'confluence.frontend.loom-entrypoint-page-header_zvj58',
	);

	const isLoomBetaOnboardingEnabled = useBooleanFeatureFlag(
		'confluence.frontend.loom-beta-onboarding_6ne6j',
	);

	const isLoomGAOnboardingEnabled = FeatureGates.checkGate('confluence_m25_onboarding_spotlights');

	const { openLoomRecorder, isOpeningRecorder, isLoomRecorderInitialized, hasRecordingAccess } =
		useLoomRecorderEntryPoint({
			entryPointLocation: 'pageHeader',
			flags,
			resetInsertFunctionOnUnmount,
			skipInitSDK: !isEntryPointEnabled,
		});

	const shouldRenderButton = isEntryPointEnabled && isPageHeaderLoomButtonEnabled;

	// activate Loom Beta onboarding if the necessary feature flags are on and conditions are met
	const shouldActivateLoomBetaOnboarding =
		shouldRenderButton &&
		entryPointVariant === 'BETA' &&
		isLoomBetaOnboardingEnabled &&
		hasRecordingAccess &&
		isLoomRecorderInitialized &&
		isContentContributor;

	const shouldActivateLoomGAOnboarding =
		shouldRenderButton &&
		entryPointVariant === 'CO_USE' &&
		isLoomGAOnboardingEnabled &&
		hasRecordingAccess &&
		isLoomRecorderInitialized;

	const [isLoomBetaOnboardingActive, stopLoomBetaOnboarding] = useSkippableCoordination(
		'cc-loom-beta-onboarding-page-header',
		!shouldActivateLoomBetaOnboarding,
	);

	const [isLoomGAOnboardingActive, stopLoomGAOnboarding] = useSkippableCoordination(
		'cc-loom-onboarding-spotlight-editor',
		!shouldActivateLoomGAOnboarding,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createLoomHeaderItem',
				source: 'pageHeader',
				attributes: {
					postLoomRecordingCTA,
				},
			},
		}).fire();

		void openLoomRecorder(openLoomRecorderParams);
	}, [createAnalyticsEvent, openLoomRecorder, openLoomRecorderParams, postLoomRecordingCTA]);

	const handleSpotlightClose = useCallback(() => {
		if (isLoomBetaOnboardingActive) {
			void stopLoomBetaOnboarding();
		} else if (isLoomGAOnboardingActive) {
			void stopLoomGAOnboarding();
		}
	}, [
		stopLoomBetaOnboarding,
		stopLoomGAOnboarding,
		isLoomBetaOnboardingActive,
		isLoomGAOnboardingActive,
	]);

	useEffect(() => {
		shouldRenderButton && buttonRendered();
	}, [buttonRendered, shouldRenderButton]);

	if (shouldRenderButton) {
		return (
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem, getAllItemsLoadState }: HeaderItemsContainer) => {
					addItem(HEADER_ITEM_KEYS.RECORD_LOOM);
					markItemRef.current = markItem;

					const LoomButtonWithTooltip = (
						<Tooltip content={formatMessage(i18n.loomButtonTooltip)}>
							{(tooltipProps) => (
								<Button
									appearance="subtle"
									iconAfter={<LoomIcon label="" size="medium" />}
									aria-label={formatMessage(i18n.loomButtonTooltip)}
									{...tooltipProps}
									onClick={handleClick}
									isLoading={isOpeningRecorder}
									data-vc="loom-button"
									{...ssrPlaceholderIdProp}
								/>
							)}
						</Tooltip>
					);

					// getAllItemsLoadState() is important because we want to wait until the entire header button group has been rendered
					// before we show the spotlight, so we don't end up with UI shifting and the spotlight icon appearing
					// over another button
					if (
						((isLoomBetaOnboardingEnabled &&
							isLoomBetaOnboardingActive &&
							entryPointVariant === 'BETA') ||
							(isLoomGAOnboardingEnabled &&
								isLoomGAOnboardingActive &&
								entryPointVariant === 'CO_USE')) &&
						getAllItemsLoadState()
					) {
						return (
							<PageHeaderLoomButtonSpotlight
								onTryClick={handleClick}
								onClose={handleSpotlightClose}
							>
								{LoomButtonWithTooltip}
							</PageHeaderLoomButtonSpotlight>
						);
					}

					return LoomButtonWithTooltip;
				}}
			</Subscribe>
		);
	}
	return null;
};

export const PageHeaderLoomButton = withErrorBoundary({
	attribution: Attribution.CONTENT_TYPES,
})(withFlags(PageHeaderLoomButtonComponent));
