import React from 'react';

import Icon from '@atlaskit/icon';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

const loomSVG = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M21.75 10.7599H16.0479L20.9862 7.90883L19.9017 6.02992L14.9634 8.88103L17.8139 3.94314L15.935 2.85802L13.0845 7.7959V2.09424H10.9155V7.79644L8.06389 2.85802L6.18557 3.9426L9.03659 8.88048L4.09831 6.02992L3.01377 7.90828L7.95205 10.7594H2.25V12.9286H7.95151L3.01377 15.7796L4.09831 17.6586L9.03605 14.808L6.18503 19.7459L8.06389 20.8304L10.9149 15.8921V21.5942H13.084V15.8926L15.9345 20.8304L17.8133 19.7459L14.9623 14.8075L19.9006 17.6586L20.9852 15.7796L16.0474 12.9291H21.7489V10.7599H21.75ZM12 14.7943C10.3645 14.7943 9.03877 13.4687 9.03877 11.8331C9.03877 10.1975 10.3645 8.87175 12 8.87175C13.6355 8.87175 14.9612 10.1975 14.9612 11.8331C14.9612 13.4687 13.6355 14.7943 12 14.7943Z"
			fill="currentColor"
		/>
	</svg>
);

export const LoomIcon = (props: GlyphProps) => {
	const primaryColor = props.primaryColor || token('color.text');
	const label = props.label || '';
	return <Icon glyph={loomSVG} {...props} primaryColor={primaryColor} label={label} />;
};
